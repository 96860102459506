import React from "react";
const AboutAi = () => {
  return (
    <>
      <section className="bg-black  pb-10">
        <div  className="max-w-7xl mx-auto px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div
              className="mt-12 md:mt-0 
               relative  rounded-lg"
            >
              <div
                className="absolute inset-0 bg-cover bg-center rounded-lg"
                style={{
                  backgroundImage: `url('/stocktrade.jpeg')`,
                  opacity: 0.8,
                }}
              ></div>
              <img
                src="/mantrader.png"
                alt="About Us Image"
                className="relative z-10 rounded-lg shadow-md -mt-16 "
                style={{
                  width: "auto",
                  height: "500px",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />
            </div>

            <div>
                <p className="text-amber-200 font-semibold lg:text-base text-sm" id="about">About BotPay</p>
              <h2 className="font-semibold text-amber-500 uppercase text-2xl md:text-3xl">
              Your Partner in Smart and Automated Trading
              </h2>
              <p className="mt-4 text-gray-200 lg:text-base text-sm text-justify">
              BotPay offers a new way to trade with easy-to-use automation tools and a platform that works for everyone, no matter your experience level. Whether you're just starting or have years of trading experience, it helps you make better choices, set up automatic strategies, and trade more efficiently.
              </p>
              <p className="mt-4 text-gray-200 lg:text-base text-sm text-justify">
              Our advanced algorithms analyze market trends in real time, allowing you to seize opportunities as they arise. Join our community of traders and discover how BotPay can elevate your trading experience to new heights.
              </p>
              <p className="mt-4 text-gray-200 lg:text-base text-sm text-justify">
              With dedicated customer support and a wealth of educational resources, we're here to guide you on your trading journey every step of the way. Experience the future of trading with confidence and discover your full potential today. Start trading with us and see how simple and rewarding trading can be!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutAi;
