import React  from 'react'
import { Header } from './Header'
import AIAgent from './AiAgent'
import AboutAi from './AboutAi'
import ForexRobotCard from './ForexRobotCard'
import Experience from './Experience'
import { Testimonial } from './Testimonial'
import { WhyChoose } from './WhyChoose'
import { Pricing } from './Pricing'
import Footer from './Footer'
import TradingViewWidget from './TradingViewWidget'
import MT5section from './Mt5section'
// import FeatureBox from './Mt5section'

export const HomePage = () => {

  return (
   <>
   <div className='bg-black'>
   <Header/>
   <AIAgent/>
   <AboutAi/>
   <ForexRobotCard/>
   <Experience/>
   <MT5section/>
   {/* <MT5Features/> */}
   <TradingViewWidget/>
   <Testimonial/>
   {/* <WhyChoose/> */}
   <Pricing/>

   <Footer/>
   </div>
   </>
  )
}
