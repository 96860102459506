import React from "react";
import { Link } from "react-router-dom";
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/20/solid";

const pricingPlans = [
  {
    title: "Standard Account",
    name: "Investment",
    price: "100 - 500",
    features: [
      "Basic access to the platform",
      "Limited trading capabilities",
      "Essential support",
      "Ideal for beginners or casual traders",
    ],
   
  },
  {
    title: "Classic Account",
    name: "Investment",
    price: "600 - 5400",
    features: [
      "Advanced trading tools",
      "Increased asset selection",
      "Priority support",
      "Perfect for intermediate traders looking to expand their portfolio",
    ],
    buttonColor: "bg-blue-600",
  },
  {
    title: "Premium Account",
    name: "Investment",
    price: "5500 Above",
    features: [
      "Premium features",
      "Exclusive insights",
      "Dedicated account manager",
      "Designed for experienced traders seeking maximum potential",
    ],
   
  },
];

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const Pricing = () => {
  return (
    <div  className="mx-auto max-w-7xl bg-black px-5 py-16 lg:py-20">
      <div className="lg:flex">
        <div className="md:flex lg:w-1/4 md:flex-col lg:px-5">
          <div className="w-full flex-grow text-left">
            <h1 id="pricing" className="lg:mb-5 mb-2 text-2xl lg:text-3xl sm:text-3xl font-bold text-amber-500">
              Pricing
            </h1>
            <h3 className="text-sm mb-5 font-medium text-gray-300 text-justify">
            At BotPay, we support traders of all levels with account types designed for your needs. Whether you're a beginner or an expert, each account offers more earning potential and features. Choose from our Standard Account or our Premium Account to find the right platform for your trading success.
            </h3>
          </div>
        </div>
        <div className="lg:w-3/4 relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-xl border border-slate-800 p-[1.5px]">
          <div className="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#0ea5e9_20deg,transparent_120deg)]"></div>
          <div className="relative z-20 flex w-full rounded-[0.60rem] bg-slate-900 p-5">
            <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              {pricingPlans.map((plan, index) => (
                <div
                  key={index}
                  className="mx-auto w-full flex flex-col rounded-md bg-gradient-to-t from-amber-500 to-black  px-4 py-8 shadow-lg shadow-blue-100 border-transparent transition-all duration-300 hover:shadow-2xl  border-2 hover:scale-105 hover:animate-pulse md:max-w-none"
                >
                  <div className="flex-grow">
                    <h2 className="mb-3 text-center font-madium uppercase text-lg text-gray-200">
                      {plan.title}
                    </h2>
                    <h2 className="mb-1 text-center  uppercase text-md text-gray-200">
                      {plan.name}
                    </h2>
                    <h3 className="mb-3 text-center text-2xl font-bold text-[#29accc]">
                      ${plan.price}
                    </h3>
                    <ul
                      role="list"
                      className={classNames("mt-4 space-y-3 text-sm leading-6 sm:mt-5")}
                    >
                      {plan.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3 text-white">
                          <CheckIcon
                            aria-hidden="true"
                            className={classNames(" h-6 w-5 flex-none")}
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-4">
                    <Link
                      to="#"
                      className={`flex items-center justify-center w-full whitespace-nowrap rounded-full uppercase px-8 py-2 font-semibold transition-colors`}
                    >
                      Sign up
                      <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
