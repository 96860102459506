import React from "react";

const AIAgent = () => {
  return (
    <>
      <div className="w-7xl mx-auto flex flex-col lg:gap-5 lg:flex-row items-center bg-black text-white px-4 pt-24 pb-16 lg:py-28 lg:px-12 rounded-lg shadow-lg">
        <div className="lg:w-2/5  w-full mb-6 lg:mb-0 text-center lg:text-left">
          <h1 id="home" className="lg:text-4xl text-xl  lg:block  text-justify  font-bold lg:mb-4 mb-2 leading-tight">
          Trade Smarter, 
            <span className="text-amber-500 block lg:mt-2">Trade Faster with BotPay</span>
          </h1>
          <p className="text-sm lg:text-base text-gray-300  text-justify lg:mb-0 mb-6">
          Find the future of trading with our AI-powered platform. Whether you're new to trading or an expert, BotPay gives you everything you need to win in today's fast-moving markets.
          </p>
          <p className="text-sm lg:text-base text-gray-300  text-justify lg:mb-0 mt-4  mb-6">
            Enjoy real-time analytics, personalized insights, and a user-friendly interface that empowers you to make informed decisions and maximize your profits.
          </p>
        </div>
        <div className="lg:w-3/5 w-full ">
          <div className="relative p-4 ">
            <div
              className="absolute inset-0 bg-cover bg-center rounded-l-full opacity-80 border-2 border-r-0 border-amber-600 "
              style={{ backgroundImage: `url('/tradingwallpaper.jpg')` }}
            ></div>
            <img
              src="/botpayai.png"
              alt="AI Agent"
              className="relative z-20 w-full h-auto max-w-sm lg:max-w-lg object-contain rounded-l-full shadow-lg -mt-16 ml-auto transform -scale-x-100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AIAgent;
