// import React, { useEffect } from 'react';

// const TradingViewWidget = () => {
//   useEffect(() => {
//     // Create the script element
//     const script = document.createElement('script');
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
//     script.async = true;
//     script.innerHTML = JSON.stringify({
//       "colorTheme": "dark",
//       "dateRange": "12M",
//       "showChart": true,
//       "locale": "en",
//       "largeChartUrl": "",
//       "isTransparent": false,
//       "showSymbolLogo": true,
//       "showFloatingTooltip": false,
//       "width": "950",
//       "height": "550",
//       "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
//       "plotLineColorFalling": "rgba(41, 98, 255, 1)",
//       "gridLineColor": "rgba(42, 46, 57, 0)",
//       "scaleFontColor": "rgba(209, 212, 220, 1)",
//       "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
//       "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
//       "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
//       "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
//       "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
//       "tabs": [
//         {
//           "title": "Indices",
//           "symbols": [
//             { "s": "FOREXCOM:SPXUSD", "d": "S&P 500 Index" },
//             { "s": "FOREXCOM:NSXUSD", "d": "US 100 Cash CFD" },
//             { "s": "FOREXCOM:DJI", "d": "Dow Jones Industrial Average Index" },
//             { "s": "INDEX:NKY", "d": "Nikkei 225" },
//             { "s": "INDEX:DEU40", "d": "DAX Index" },
//             { "s": "FOREXCOM:UKXGBP", "d": "FTSE 100 Index" }
//           ],
//           "originalTitle": "Indices"
//         },
//         {
//           "title": "Futures",
//           "symbols": [
//             { "s": "CME_MINI:ES1!", "d": "S&P 500" },
//             { "s": "CME:6E1!", "d": "Euro" },
//             { "s": "COMEX:GC1!", "d": "Gold" },
//             { "s": "NYMEX:CL1!", "d": "WTI Crude Oil" },
//             { "s": "NYMEX:NG1!", "d": "Gas" },
//             { "s": "CBOT:ZC1!", "d": "Corn" }
//           ],
//           "originalTitle": "Futures"
//         },
//         {
//           "title": "Bonds",
//           "symbols": [
//             { "s": "CBOT:ZB1!", "d": "T-Bond" },
//             { "s": "CBOT:UB1!", "d": "Ultra T-Bond" },
//             { "s": "EUREX:FGBL1!", "d": "Euro Bund" },
//             { "s": "EUREX:FBTP1!", "d": "Euro BTP" },
//             { "s": "EUREX:FGBM1!", "d": "Euro BOBL" }
//           ],
//           "originalTitle": "Bonds"
//         },
//         {
//           "title": "Forex",
//           "symbols": [
//             { "s": "FX:EURUSD", "d": "EUR to USD" },
//             { "s": "FX:GBPUSD", "d": "GBP to USD" },
//             { "s": "FX:USDJPY", "d": "USD to JPY" },
//             { "s": "FX:USDCHF", "d": "USD to CHF" },
//             { "s": "FX:AUDUSD", "d": "AUD to USD" },
//             { "s": "FX:USDCAD", "d": "USD to CAD" }
//           ],
//           "originalTitle": "Forex"
//         }
//       ]
//     });
    
//     // Find the container and append the script
//     document.getElementById('tradingview-widget-container').appendChild(script);
//   }, []);

//   return (
//     <div className="tradingview-widget-container m-auto py-10" id="tradingview-widget-container">
//       <div className="tradingview-widget-container__widget"></div>
//       <div className="tradingview-widget-copyright">
//         <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
//           <span className="blue-text">Track all markets on TradingView</span>
//         </a>
//       </div>
//     </div>
//   );
// };

// export default TradingViewWidget;


import React, { useEffect, useRef } from 'react';

const TradingViewWidget = () => {
  const widgetRef = useRef(null); // Create a reference to the widget container

  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      "colorTheme": "dark",
      "dateRange": "12M",
      "showChart": true,
      "locale": "en",
      "largeChartUrl": "",
      "isTransparent": false,
      "showSymbolLogo": true,
      "showFloatingTooltip": false,
      "width": "100%", // Use 100% to make it responsive
      "height": "650", // Use 100% for responsive height
      "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
      "plotLineColorFalling": "rgba(41, 98, 255, 1)",
      "gridLineColor": "rgba(42, 46, 57, 0)",
      "scaleFontColor": "rgba(209, 212, 220, 1)",
      "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
      "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
      "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
      "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
      "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
      "tabs": [
        {
          "title": "Indices",
          "symbols": [
            { "s": "FOREXCOM:SPXUSD", "d": "S&P 500 Index" },
            { "s": "FOREXCOM:NSXUSD", "d": "US 100 Cash CFD" },
            { "s": "FOREXCOM:DJI", "d": "Dow Jones Industrial Average Index" },
            { "s": "INDEX:NKY", "d": "Nikkei 225" },
            { "s": "INDEX:DEU40", "d": "DAX Index" },
            { "s": "FOREXCOM:UKXGBP", "d": "FTSE 100 Index" }
          ],
          "originalTitle": "Indices"
        },
        {
          "title": "Futures",
          "symbols": [
            { "s": "CME_MINI:ES1!", "d": "S&P 500" },
            { "s": "CME:6E1!", "d": "Euro" },
            { "s": "COMEX:GC1!", "d": "Gold" },
            { "s": "NYMEX:CL1!", "d": "WTI Crude Oil" },
            { "s": "NYMEX:NG1!", "d": "Gas" },
            { "s": "CBOT:ZC1!", "d": "Corn" }
          ],
          "originalTitle": "Futures"
        },
        {
          "title": "Bonds",
          "symbols": [
            { "s": "CBOT:ZB1!", "d": "T-Bond" },
            { "s": "CBOT:UB1!", "d": "Ultra T-Bond" },
            { "s": "EUREX:FGBL1!", "d": "Euro Bund" },
            { "s": "EUREX:FBTP1!", "d": "Euro BTP" },
            { "s": "EUREX:FGBM1!", "d": "Euro BOBL" }
          ],
          "originalTitle": "Bonds"
        },
        {
          "title": "Forex",
          "symbols": [
            { "s": "FX:EURUSD", "d": "EUR to USD" },
            { "s": "FX:GBPUSD", "d": "GBP to USD" },
            { "s": "FX:USDJPY", "d": "USD to JPY" },
            { "s": "FX:USDCHF", "d": "USD to CHF" },
            { "s": "FX:AUDUSD", "d": "AUD to USD" },
            { "s": "FX:USDCAD", "d": "USD to CAD" }
          ],
          "originalTitle": "Forex"
        }
      ]
    });
    
    // Append the script to the widget container
    widgetRef.current.appendChild(script);
  }, []);

  return (
    <>    
    <div className='max-w-7xl mx-auto lg:mt-10 mt-4 lg:px-12'>
      <div className='lg:text-center  px-4'>
        <span className='text-amber-200 text-sm '>Market overview</span>
        <h1 className='text-amber-500 font-semibold lg:text-3xl text-lg uppercase  '>Forex Trading Market overview</h1>
      </div>
    <div className="tradingview-widget-container m-auto pb-10 pt-6 px-4 lg:px-2" ref={widgetRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener noreferrer" target="_blank">
          <span className="text-amber-500 lg:mb-0 mb-4">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
    </div>
    </>

  );
};

export default TradingViewWidget;
