import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "../BaseFile/comman/Loader"
import SuccessAlert from "../BaseFile/comman/SuccessAlert"
import ErrorAlert from "../BaseFile/comman/ErrorAlert"
import { getUser, updateUsers,clearErrors, clearMessage } from "../redux/userSlice";
export default function UserProfile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleuser, loading, error, message } = useSelector(
    (state) => state.allusers
  );
  const [editUser, setEditUser] = useState([]);

  useEffect(() => {
    dispatch(getUser(id));
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, clearErrors, clearMessage, id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {

    const updatedData = {
      ...editUser,
      updated_at: new Date().toISOString(),
    };
    dispatch(
      updateUsers({
        id: id,
        updatedData: updatedData,
      }
    )
    );
  };
  return (
    <>
     
<div
  className={`${
    loading ? "h-[560px] items-center" : "h-full"
  }`}
>
  {loading ? (
    <Loader />
  ) : (
    <>
      <div className="bg-black">
        <div className="max-w-full bg-gray-800 rounded shadow overflow-hidden">
          <div className="px-6 py-4">
            <h2 className="text-xl capitalize font-semibold text-gray-200 mb-4">
              {singleuser?.username} Detail
            </h2>
            <div className="flex flex-wrap gap-4 ">
              <div className="w-full sm:w-1/2 flex gap-4 justify-between">
                <div className="font-medium text-gray-300">Last Login</div>
                <div className="text-gray-300">
                  {singleuser?.last_login || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4 justify-between">
                <div className="font-medium text-gray-300">Created At</div>
                <div className="text-gray-300">
                  {singleuser?.created_at || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4 justify-between">
                <div className="font-medium text-gray-300">Verify At</div>
                <div className="text-gray-300">
                  {singleuser?.verify_at || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4 justify-between">
                <div className="font-medium text-gray-300">Updated At</div>
                <div className="text-gray-300">
                  {singleuser?.updated_at || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4 justify-between">
                <div className="font-medium text-gray-300">Reffer By</div>
                <div className="text-gray-300">
                  {singleuser?.reffer_by || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4 justify-between">
                <div className="font-medium text-gray-300">Activation Date</div>
                <div className="text-gray-300">
                  {singleuser?.activation_date || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4 ">
                <div className="font-medium text-gray-300">Paid Member</div>
                <div className="text-gray-300">
                  {singleuser?.paid_member || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4">
                <div className="font-medium text-gray-300">Refferal Code</div>
                <div className="text-gray-300">
                  {singleuser?.refferal_code || " - "}
                </div>
              </div>
              <div className="w-full sm:w-1/2 flex gap-4">
                <div className="font-medium text-gray-300">Total Team</div>
                <div className="text-gray-300">
                  {singleuser?.total_team || " - "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[2px] bg-gray-800 w-full border-t" />
      <div className="bg-black">
        <div className="max-w-full bg-gray-800 rounded shadow overflow-hidden">
          {message && <SuccessAlert message={message} />}
          {error && <ErrorAlert error={error} />}
          <div className="px-6 py-4">
            <h2 className="text-xl font-semibold text-gray-300 mb-5">
              Editable Details
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.username}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.password}
                  onChange={handleChange}
                />
              </div>
        
    
          
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.address || ""}
                  onChange={handleChange}
                />
              </div>
     
           
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Full Name
                </label>
                <input
                  type="text"
                  name="fullname"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.fullname || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.phone || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Beb20 USDT Address
                </label>
                <input
                  type="text"
                  name="Beb20"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.Beb20 || ""}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  TRX20 USDT Address
                </label>
                <input
                  type="text"
                  name="TRX20"
                  className="mt-1 block w-full py-1 bg-black text-gray-300 px-3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  defaultValue={singleuser?.TRX20 || ""}
                  onChange={handleChange}
                />
              </div>
        
               */}
            </div>
            <div className="flex justify-end mt-6">
              <button
                className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )}
</div>

    </>
  );
}
