import React from 'react'

const Experience = () => {
  return (
   <>
<section className="relative p-[2px] lg:mx-0 mx-4 bg-black">
  <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-[#29b5e6] to-amber-600 opacity-50"></div>
  <div className="relative z-10 bg-black lg:p-4 rounded-lg ">
        <div className="max-w-7xl mx-auto px-4 py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div>
                <p className="text-amber-200 font-semibold">First Trading Robot 
                </p>
              <h2 className="font-semibold text-amber-500 uppercase text-2xl md:text-3xl">
              Transforming Your Trading Journey
              </h2>
              <p className="mt-4 text-gray-200 text-base text-justify">
              Step into the future of trading with our cutting-edge robot. Designed for the fast-paced world of forex, our AI-powered assistant tirelessly searches for profitable opportunities, allowing you to focus on what matters most to you. With its advanced algorithms, our robot quickly analyzes vast amounts of market data, executing trades with accuracy and speed that far exceeds human abilities.

              </p>
              <p className="mt-4 text-gray-200 text-base text-justify">
              Whether you’re an experienced trader looking to enhance your strategy or a newcomer seeking a dependable way to enter the market, our robot adapts to your needs. It provides customizable risk management and various trading styles to suit your preferences. Enjoy the peace of mind that comes with 24/7 market monitoring, automated trade execution, and real-time updates to your portfolio.
              </p>
            </div>
            <div> 
                <img src='/botpreview.png' className='w-full'/>
            </div>
            
          </div>
        </div>
        </div>
        
      </section>
      Testimonial
   
   </>
  )
}

export default Experience