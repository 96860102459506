import React from "react";

const FeatureBox = ({ image, title, description }) => {
  return (
    <div className="bg-gray-800 text-center p-6 rounded-lg shadow-lg">
       <h3 className="text-amber-500 font-semibold text-lg  pb-2">{title}</h3>
      {/* <FontAwesomeIcon icon={icon} className="text-purple-400 text-4xl mb-4" /> */}
      <img src={image} alt={title} className="w-24 h-24 mb-4 mx-auto" />
      <p className="text-gray-50 text-sm mt-2">{description}</p>
    </div>
  );
};

const MT5section = () => {
  return (
    <div className=" lg:flex flex-col items-center lg:justify-center lg:p-8 p-4">
      <div> 
      <h6 className="text-sm text-amber-200 mb-1 capitalize "> Innovative trading platform</h6>
      <h1 className="lg:text-3xl text-xl lg:text-center text-justify font-semibold text-amber-500 lg:mb-8 mb-4">HOW MT5 IS THE BEST?</h1>
      </div>
      {/* Grid layout with 3 columns on large screens, auto-adjusts on smaller screens */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl">
        <FeatureBox
           image="/market.png"
          title="POWERFUL"
          description="High-performance trading engine capable of handling complex algorithms and high-frequency trades."
        />
        <FeatureBox
          image="/trader.png"
          title="VERSATILE"
          description="Support for multiple asset classes including Forex, Stocks, Commodities, and Cryptocurrencies"
        />
        <FeatureBox
           image="/profit.png"
          title="SECURE"
          description="Advanced encryption and security protocols to protect your trades and personal data."
        />
        <FeatureBox
          image="/sales.png"
          title="RELIABLE"
          description="Trusted by millions of traders worldwide for its stability and consistent performance."
        />
        <FeatureBox
           image="/winner.png"
          title="INNOVATIVE"
          description="Continuous updates with cutting-edge features for advanced market analysis and automated trading."
        />
        <FeatureBox
          image="/stockmarkets.png"
          title="EFFICIENT"
          description="Multi-threaded strategy tester for optimizing and back-testing trading robots with unparalleled speed."
        />
      </div>
    </div>
  );
};

export default MT5section;
