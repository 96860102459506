import React, { useEffect } from "react";

const TradingViewWidget = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js";
    script.async = true;

    // Add the widget settings
    script.innerHTML = JSON.stringify({
      width: 950,
      height: 400,
      currencies: ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD"],
      isTransparent: false,
      colorTheme: "dark",
      locale: "en",
      backgroundColor: "#000000",
    });

    // Append the script to the container
    const container = document.getElementById("tradingview-widget");
    if (container) {
      container.appendChild(script);
    }

    // Cleanup: remove the script when component unmounts
    return () => {
      if (container) {
        container.innerHTML = ""; // clear the widget on unmount
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview-widget"></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span className="text-black">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TradingViewWidget;

