import React, { useState, useEffect } from 'react'
import {
  Bars3Icon,
  XMarkIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Link } from "react-router-dom";
const navigation = [
  { name: "Home", href: "#home" },
  { name: "About Us", href: "#about" },
  { name: "Why Choose", href: "#choose" },
  { name: "Priceing", href: "#pricing" },
  // { name: "Pricing", href: "#pricing" },
];
export const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <>
    
    <header className="fixed bg-black shadow-sm inset-x-0 top-0 z-50 border-amber-500  border-b-2">
          <nav className="flex items-center justify-between p-4 lg:px-8 ">
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img alt="Logo" src="./botpaywhite.png" className="h-10 w-40" />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm uppercase leading-6 text-white"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-5 uppercase ">
              <Link
                to="/user/login"
                className="text-sm border-2  px-6 py-1 rounded-md shadow-sm hover:shadow-xl leading-6 text-white bg-amber-500 border-amber-200"
              >
                Sign In
              </Link>
              <Link
                to="/registration"
                className="border-2 px-6 py-1 rounded-md text-sm  leading-6 text-white bg-amber-500 border-amber-200"
              >
                Sign Up
              </Link>
            </div>
          </nav>
          {/* Mobile Menu */}
          <Dialog
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
            className="lg:hidden"
          >
            <div className="fixed inset-0 z-50" />
            <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
              <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img alt="Logo" src="./botpaywhite.png" className="h-10 w-40" />
              </a>
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base uppercase leading-7 text-white hover:bg-gray-800"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6 uppercase">
                    <Link
                     to="/user/login"
                      className="me-4 text-sm border px-5 py-1 rounded-full  leading-6 text-white"
                    >
                     Login
                    </Link>
                    <Link
                      to="/registration"
                      className="border px-5 py-1 rounded-full text-sm  leading-6 text-white"
                    >
                     Open Account
                    </Link>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>
    </>
  )
}
