// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getTreeData } from "../redux/referralSlice";

// // Function to generate a random color
// const getRandomColor = () => {
//   const letters = "0123456789ABCDEF";
//   let color = "#";
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

// // TreeNode Component
// const TreeNode = ({ user, expandedNodes, toggleExpand }) => {
//   const [bgColor] = useState(getRandomColor()); // Generate random color once per node

//   return (
//     <div className="tree-node flex flex-col items-center p-4" style={{ backgroundColor: bgColor }}>
//       <div onClick={() => toggleExpand(user.id)} className="flex items-center mx-8">
//         <div className="relative" >
//           <img
//             src="/default.jpg" // Placeholder image
//             alt={user?.username}
//             className="w-10 h-10 rounded-full border border-gray-300 cursor-pointer"
//           />
//           {user?.referrals && user?.referrals.length > 0 && (
//             <div className="absolute w-0.5 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div>
//           )}
//         </div>
//         <div className="ml-2">
//           <div className="font-semibold">{user?.username}</div>
//           <div>ID: {user?.id}</div>
//           <div>Email: {user?.email}</div>
//           <div>Status: {user?.is_active}</div>
//         </div>
//       </div>

//       {/* Show children if expanded */}
//       {expandedNodes[user.id] && user.referrals && user.referrals.length > 0 && (
//         <div className="mt-4 flex flex-col items-center">
//           <div className="flex flex-wrap justify-center">
//             {user.referrals.map((childUser) => (
//               <div key={childUser.id} className="mb-4 mx-2">
//                 <TreeNode
//                   user={childUser}
//                   expandedNodes={expandedNodes}
//                   toggleExpand={toggleExpand} // Pass the same toggle function to handle nested nodes
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // UserReferralTree Component
// const UserReferralTree = () => {
//   const dispatch = useDispatch();
//   const [error, setError] = useState(null);
//   const { auth } = useSelector((state) => state.auth);
//   const { treeData } = useSelector((state) => state.referralTree);
//   const [expandedNodes, setExpandedNodes] = useState({}); // Track which nodes are expanded

//   useEffect(() => {
//     if (auth?.refferal_code) {
//       dispatch(getTreeData(auth?.refferal_code));
//     }
//   }, [auth?.refferal_code]);

//   // Toggle expand/collapse for a node
//   const toggleExpand = (id) => {
//     setExpandedNodes((prev) => ({
//       ...prev,
//       [id]: !prev[id], // Toggle the current state
//     }));
//   };

//   return (
//     <div className="flex flex-col items-center">
//       <div className="flex justify-center items-center">
//         <div className="relative">
//           <img
//             src="/default.jpg" // Placeholder image
//             alt={auth?.username}
//             className="w-10 h-10 rounded-full border border-gray-300"
//           />
//           <div className="absolute w-0.5 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div>
//         </div>
//         <div className="ml-2 text-white">
//           <div className="font-semibold">{auth?.username}</div>
//           <div>ID: {auth?.id}</div>
//           <div>Email: {auth?.email}</div>
//           <div>Status: {auth?.is_active}</div>
//         </div>
//       </div>

//       <div className="p-6 flex justify-center">
//         {error ? (
//           <p>{error}</p>
//         ) : treeData?.length > 0 ? (
//           <div className="w-full overflow-x-auto">
//             <div className="flex justify-center items-center flex-wrap">
//               {treeData?.map((user) => (
//                 <div key={user.id} className="mx-auto mb-6">
//                   {/* Show direct members initially, but grandchildren can be expanded recursively */}
//                   <TreeNode
//                     user={user}
//                     expandedNodes={expandedNodes}
//                     toggleExpand={toggleExpand}
//                   />
//                 </div>
//               ))}
//             </div>
//           </div>
//         ) : (
//           <div className="text-white text-center">No More Tree</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default UserReferralTree;

// import React, { useState } from "react";
// import "./ReferralTree.css"; // Import your CSS file here

// const GenealogyTree = () => {
//   const [activeNodes, setActiveNodes] = useState({});

//   const toggleNode = (nodeId) => {
//     setActiveNodes((prevState) => ({
//       ...prevState,
//       [nodeId]: !prevState[nodeId],
//     }));
//   };

//   const renderTreeNode = (nodeId, label, children = []) => {
//     return (
//       <li key={nodeId} className="relative flex flex-col items-center">
//         <a
//           href="#"
//           onClick={(e) => {
//             e.preventDefault();
//             toggleNode(nodeId);
//           }}
//           className="border border-gray-300 py-2 px-4 rounded hover:bg-blue-100"
//         >
//           {label}
//         </a>

//         {/* Render child nodes if expanded */}
//         {activeNodes[nodeId] && children.length > 0 && (
//           <ul className="flex justify-center pt-4 relative space-x-8">
//             {children.map((child, index) =>
//               renderTreeNode(`${nodeId}-${index}`, child.label, child.children)
//             )}
//           </ul>
//         )}
//       </li>
//     );
//   };

//   const treeData = [
//     {
//       label: "1",
//       children: [
//         {
//           label: "2",
//           children: [
//             { label: "2.1", children: [] },
//             { label: "2.2", children: [] },
//           ],
//         },
//         {
//           label: "3",
//           children: [
//             {
//               label: "3.1",
//               children: [
//                 { label: "3.1.1", children: [] },
//                 { label: "3.1.2", children: [] },
//               ],
//             },
//             { label: "3.2", children: [] },
//           ],
//         },
//       ],
//     },
//   ];

//   return (
//     <div className="py-12 overflow-auto genealogy-scroll whitespace-nowrap min-h-screen text-center">
//       <div className="tree inline-block">
//         <ul className="flex justify-center pt-5 space-x-8">
//           {treeData.map((node, index) =>
//             renderTreeNode(index, node.label, node.children)
//           )}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default GenealogyTree;
// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getTreeData } from "../redux/referralSlice";
// import "./ReferralTree.css"; // Import your CSS file here

// // Function to generate a random color
// const getRandomColor = () => {
//   const letters = "0123456789ABCDEF";
//   let color = "#";
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

// // TreeNode Component
// const TreeNode = ({ user, expandedNodes, toggleExpand }) => {
//   const [bgColor] = useState(getRandomColor()); // Generate random color once per node

//   return (
//     <li className="relative flex flex-col items-center">
//       {/* Before condition */}
//       <div className="before-condition text-sm text-gray-500">
//         Before: {user.beforeCondition || "N/A"}
//       </div>

//       <a
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           toggleExpand(user.id);
//         }}
//         className="border border-gray-300 py-2 px-4 rounded hover:bg-blue-100 flex items-center"
//         style={{ backgroundColor: bgColor }}
//       >
//         <div className="relative">
//           <img
//             src="/default.jpg" // Placeholder image
//             alt={user?.username}
//             className="w-10 h-10 rounded-full border border-gray-300 cursor-pointer"
//           />
//           {user?.referrals && user?.referrals.length > 0 && (
//             <div className="absolute w-0 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div>
//           )}
//         </div>
//         <div className="ml-2">
//           <div className="font-semibold">{user?.username}</div>
//           <div>ID: {user?.id}</div>
//           <div>Email: {user?.email}</div>
//           <div>Status: {user?.is_active}</div>
//         </div>
//       </a>

//       {/* After condition */}
//       <div className="after-condition text-sm text-gray-500">
//         After: {user.afterCondition || "N/A"}
//       </div>

//       {/* Show children if expanded */}
//       {expandedNodes[user.id] && user.referrals && user.referrals.length > 0 && (
//         <ul className="flex justify-center pt-4 relative space-x-8">
//           {user.referrals.map((childUser) => (
//             <li key={childUser.id} className="mb-4 mx-2">
//               <TreeNode
//                 user={childUser}
//                 expandedNodes={expandedNodes}
//                 toggleExpand={toggleExpand}
//               />
//             </li>
//           ))}
//         </ul>
//       )}
//     </li>
//   );
// };

// // UserReferralTree Component
// const UserReferralTree = () => {
//   const dispatch = useDispatch();
//   const [error, setError] = useState(null);
//   const { auth } = useSelector((state) => state.auth);
//   const { treeData } = useSelector((state) => state.referralTree);
//   const [expandedNodes, setExpandedNodes] = useState({}); // Track which nodes are expanded

//   useEffect(() => {
//     if (auth?.refferal_code) {
//       dispatch(getTreeData(auth?.refferal_code));
//     }
//   }, [auth?.refferal_code]);

//   // Toggle expand/collapse for a node
//   const toggleExpand = (id) => {
//     setExpandedNodes((prev) => ({
//       ...prev,
//       [id]: !prev[id], // Toggle the current state
//     }));
//   };

//   return (
//     <div className="py-12 overflow-auto genealogy-scroll whitespace-nowrap min-h-screen text-center">
//       <div className="flex justify-center items-center mb-6">
//         <div className="relative">
//           <img
//             src="/default.jpg" // Placeholder image
//             alt={auth?.username}
//             className="w-10 h-10 rounded-full border border-gray-300"
//           />
//           {/* <div className="absolute w-0.5 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div> */}
//         </div>
//         <div className="ml-2 text-white">
//           <div className="font-semibold">{auth?.username}</div>
//           <div>ID: {auth?.id}</div>
//           <div>Email: {auth?.email}</div>
//           <div>Status: {auth?.is_active}</div>
//         </div>
//       </div>

//       <div className="tree inline-block">
//         <ul className="flex justify-center pt-5 space-x-8">
//           {treeData?.map((user) => (
//             <TreeNode
//               key={user.id}
//               user={user}
//               expandedNodes={expandedNodes}
//               toggleExpand={toggleExpand}
//             />
//           ))}
//         </ul>
//       </div>

//       {error && <p>{error}</p>}
//       {treeData?.length === 0 && <div className="text-white text-center">No More Tree</div>}
//     </div>
//   );
// };

// export default UserReferralTree;

// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getTreeData } from "../redux/referralSlice";
// import "./ReferralTree.css"; // Import your CSS file here

// // Function to generate a random color
// const getRandomColor = () => {
//   const letters = "0123456789ABCDEF";
//   let color = "#";
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

// // TreeNode Component
// const TreeNode = ({ user, expandedNodes, toggleExpand, bgColor }) => {
//   return (
//     <li className="relative flex flex-col items-center">
//       {/* Before condition */}
//       <div className="before-condition text-sm text-gray-500">
//         Before: {user.beforeCondition || "N/A"}
//       </div>

//       <a
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           toggleExpand(user.id);
//         }}
//         className="border border-gray-300 py-2 px-4 rounded hover:bg-blue-100 flex items-center"
//         style={{ backgroundColor: bgColor }} // Use the passed color
//       >
//         <div className="relative">
//           <img
//             src="/default.jpg" // Placeholder image
//             alt={user?.username}
//             className="w-10 h-10 rounded-full border border-gray-300 cursor-pointer"
//           />
//           {user?.referrals && user?.referrals.length > 0 && (
//             <div className="absolute w-0 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div>
//           )}
//         </div>
//         <div className="ml-2">
//           <div className="font-semibold">{user?.username}</div>
//           <div>ID: {user?.id}</div>
//           <div>Email: {user?.email}</div>
//           <div>Status: {user?.is_active}</div>
//         </div>
//       </a>

//       {/* After condition */}
//       <div className="after-condition text-sm text-gray-500">
//         After: {user.afterCondition || "N/A"}
//       </div>

//       {/* Show children if expanded */}
//       {expandedNodes[user.id] && user.referrals && user.referrals.length > 0 && (
//         <ul className="flex justify-center pt-4 relative space-x-8">
//           {user.referrals.map((childUser) => (
//             <li key={childUser.id} className="mb-4 mx-2">
//               <TreeNode
//                 user={childUser}
//                 expandedNodes={expandedNodes}
//                 toggleExpand={toggleExpand}
//                 bgColor={bgColor} // Pass the color down
//               />
//             </li>
//           ))}
//         </ul>
//       )}
//     </li>
//   );
// };

// // UserReferralTree Component
// const UserReferralTree = () => {
//   const dispatch = useDispatch();
//   const [error, setError] = useState(null);
//   const { auth } = useSelector((state) => state.auth);
//   const { treeData } = useSelector((state) => state.referralTree);
//   const [expandedNodes, setExpandedNodes] = useState({}); // Track which nodes are expanded
//   const [bgColor, setBgColor] = useState(getRandomColor()); // Generate random color once for the tree

//   useEffect(() => {
//     if (auth?.refferal_code) {
//       dispatch(getTreeData(auth?.refferal_code));
//     }
//   }, [auth?.refferal_code]);

//   // Toggle expand/collapse for a node
//   const toggleExpand = (id) => {
//     setExpandedNodes((prev) => ({
//       ...prev,
//       [id]: !prev[id], // Toggle the current state
//     }));
//   };

//   return (
//     <div className="py-12 overflow-auto genealogy-scroll whitespace-nowrap min-h-screen text-center">
//       <div className="flex justify-center items-center mb-6">
//         <div className="relative">
//           <img
//             src="/default.jpg" // Placeholder image
//             alt={auth?.username}
//             className="w-10 h-10 rounded-full border border-gray-300"
//           />
//         </div>
//         <div className="ml-2 text-white">
//           <div className="font-semibold">{auth?.username}</div>
//           <div>ID: {auth?.id}</div>
//           <div>Email: {auth?.email}</div>
//           <div>Status: {auth?.is_active}</div>
//         </div>
//       </div>

//       <div className="tree inline-block">
//         <ul className="flex justify-center pt-5 space-x-8">
//           {treeData?.map((user) => (
//             <TreeNode
//               key={user.id}
//               user={user}
//               expandedNodes={expandedNodes}
//               toggleExpand={toggleExpand}
//               bgColor={bgColor} // Pass the color down to TreeNode
//             />
//           ))}
//         </ul>
//       </div>

//       {error && <p>{error}</p>}
//       {treeData?.length === 0 && <div className="text-white text-center">No More Tree</div>}
//     </div>
//   );
// };

// export default UserReferralTree;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTreeData } from "../redux/referralSlice";
import "./ReferralTree.css"; // Import your CSS file here

// TreeNode Component
const TreeNode = ({ user, expandedNodes, toggleExpand, isWhite }) => {
  // Define colors for the tree nodes
  const bgColor = isWhite ? "#FFFFFF" : "#D1D5DB"; // White or Gray based on index
  const borderColor = isWhite ? "border-gray-400" : "border-gray-300"; // Alternating borders

  return (
    <li className="relative flex flex-col items-center">
      {/* Before condition */}
      <div className="before-condition text-sm text-gray-500">
        {/* Before: {user.beforeCondition || "N/A"} */}
      </div>

      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          toggleExpand(user.id);
        }}
        className={`border ${borderColor} py-2 px-4 rounded hover:bg-blue-100 flex items-center`}
        style={{ backgroundColor: bgColor }} // Use the defined background color
      >
        <div className="relative">
          <img
            src="/default.jpg" // Placeholder image
            alt={user?.username}
            className="w-10 h-10 rounded-full border border-gray-300 cursor-pointer"
          />
          {user?.referrals && user?.referrals.length > 0 && (
            <div className="absolute w-0 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div>
          )}
        </div>
        <div className="ml-2">
          <div className="font-semibold">{user?.username}</div>
          <div>ID: {user?.id}</div>
          <div>Email: {user?.email}</div>
          <div>Status: {user?.is_active ? "Active" : "Inactive"}</div>
        </div>
      </a>

      {/* After condition */}
      <div className="after-condition text-sm text-gray-500">
        {/* After: {user.afterCondition || "N/A"} */}
      </div>

      {/* Show children if expanded */}
      {expandedNodes[user.id] &&
        user.referrals &&
        user.referrals.length > 0 && (
          <ul className="flex justify-center pt-4 relative space-x-8">
            {user.referrals.map((childUser, index) => (
              <li key={childUser.id} className="mb-4 mx-2">
                <TreeNode
                  user={childUser}
                  expandedNodes={expandedNodes}
                  toggleExpand={toggleExpand}
                  isWhite={index % 2 === 0} // Alternate color based on index
                />
              </li>
            ))}
          </ul>
        )}
    </li>
  );
};

// UserReferralTree Component
const UserReferralTree = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { auth } = useSelector((state) => state.auth);
  const { treeData } = useSelector((state) => state.referralTree);
  const [expandedNodes, setExpandedNodes] = useState({}); // Track which nodes are expanded

  useEffect(() => {
    if (auth?.refferal_code) {
      dispatch(getTreeData(auth?.refferal_code));
    }
  }, [auth?.refferal_code, dispatch]);

  // Toggle expand/collapse for a node
  const toggleExpand = (id) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the current state
    }));
  };

  return (
    <div className="py-12 overflow-auto genealogy-scroll whitespace-nowrap min-h-screen text-center bg-gray-900">
      <div className="flex justify-center items-center mb-6">
        <div className="relative">
          <img
            src="/default.jpg" // Placeholder image
            alt={auth?.username}
            className="w-10 h-10 rounded-full border border-gray-300"
          />
        </div>
        <div className="ml-2 text-white">
          <div className="font-semibold">{auth?.username}</div>
          <div>ID: {auth?.id}</div>
          <div>Email: {auth?.email}</div>
          <div>Status: {auth?.is_active ? "Active" : "Inactive"}</div>
        </div>
      </div>

      <div className="tree inline-block">
        <ul className="flex justify-center pt-5 space-x-8">
          {treeData?.map((user, index) => {
            return (
              <>
                {/* <p className="text-white">{user.referrals.length > 0 ?"has-referrals" : "not-referrals"}</p> */}
                <TreeNode
                  key={user.id}
                  user={user}
                  expandedNodes={expandedNodes}
                  toggleExpand={toggleExpand}
                  isWhite={index % 2 === 0} // Pass the alternating color state
                />
              </>
            );
          })}
        </ul>
      </div>

      {error && <p>{error}</p>}
      {treeData?.length === 0 && (
        <div className="text-white text-center">No More Tree</div>
      )}
    </div>
  );
};

export default UserReferralTree;
