// import React from "react";

// const testimonials = [
//   {
//     title: "Software Developer",
//     description:
//       "Adaptable developer with experience in frontend (Nuxt.js) and backend (Laravel/Nest.js). Passionate about web development, quick learner, and committed to exceptional websites. Let's collaborate!",
//     image: "https://lh3.googleusercontent.com/a/ACg8ocIexhmmTS8LcwWo1fPGY5Fl3KXpd-JuBE_Gj56P3rUR2g=s96-c",
//   },
//   {
//     title: "Software Developer",
//     description:
//       "Adaptable developer with experience in frontend (Nuxt.js) and backend (Laravel/Nest.js). Passionate about web development, quick learner, and committed to exceptional websites. Let's collaborate!",
//     image:
//       "https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw2fHxwZW9wbGV8ZW58MHwwfHx8MTcxODQ3NDU1Mnww&ixlib=rb-4.0.3&q=80&w=1080",
//   },
// ];

// export const Testimonial = () => {
//   return (
//     <div className="w-full lg:mx-10 mx-2 container py-10">
//         <div className=" text-center">
//             <span className=" text-base text-purple-600">Testimonials</span>
//             <h1 className="lg:text-3xl font-semibold text-lg text-[#29b5e6] uppercase">What Our Client's say</h1>

//         </div>
//       <div className="w-full h-full py-20 flex flex-wrap  items-center gap-8">
//         {testimonials.map((testimonial, index) => (
//           <div
//             key={index}
//             className="relative group"
//             style={{ width: "20rem" }}
//           >
//             {/* Gradient border */}
//             <div className="p-[1px] bg-gradient-to-t from-blue-500 to-purple-600 rounded-lg">
//               {/* Card content */}
//               <div className="w-full h-full px-4 py-6  bg-gray-900 rounded-lg transform group-hover:scale-105 transition duration-300 ease-in-out shadow-lg">
//                 <div className="w-full flex justify-center items-center">
//                   <img
//                     className="w-[8rem] h-[8rem] rounded-full outline outline-offset-2 outline-1 outline-blue-400 shadow-lg relative -top-[4rem]"
//                     src={testimonial.image}
//                     alt={`${testimonial.name} Profile Image`}
//                   />
//                 </div>
//                 <div className="w-full h-full text-center flex flex-col gap-4 relative -top-10">
//                   <h1 className="uppercase text-base font-semibold text-white">
//                     {testimonial.title}
//                   </h1>
//                   <p className="text-[13px] text-gray-200 text-justify">
//                     {testimonial.description}
//                   </p>

//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

import React from "react";

export const Testimonial = () => {
  return (
    <div className=" mx-auto max-w-5xl lg:py-10 py-5 px-4">
      <div className="lg:text-center text-justify">
        <span className="text-base text-amber-200">Testimonials</span>
        <h1 className="lg:text-3xl font-semibold text-lg text-amber-500 uppercase">
        What Our Clients Say
        </h1>
      </div>
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 lg:gap-y-0 gap-y-16 lg:mt-28 mt-20 ">
        <div className="relative group" >
          <div className="p-[1px] bg-gradient-to-t from-blue-500 to-amber-600 rounded-lg">
            <div className="w-full h-full px-4 py-6 bg-gray-900 rounded-lg transform group-hover:scale-105 transition duration-300 ease-in-out shadow-lg">
              <div className="w-full flex justify-center items-center">
                <img
                  className="w-[8rem] h-[8rem] rounded-full outline outline-offset-2 outline-1 outline-amber-400 shadow-lg relative -top-[5rem]"
                  src="https://lh3.googleusercontent.com/a/ACg8ocIexhmmTS8LcwWo1fPGY5Fl3KXpd-JuBE_Gj56P3rUR2g=s96-c"
                  alt="Profile Image"
                />
              </div>
              <div className="w-full h-full text-center flex flex-col gap-4 pt-10 relative -top-10">
                <h1 className="uppercase text-base font-semibold text-amber-500">
                John D.
                </h1>
                <p className="text-[13px] text-gray-200 text-justify">
                BotPay has completely transformed the way I trade. The automated bots work flawlessly, and I love the real-time analytics. It’s like having a personal trading assistant!
                </p>
                <div class="flex space-x-2 text-yellow-400 justify-center mt-2">
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative group " >
          <div className="p-[1px] bg-gradient-to-t from-blue-500 to-amber-600 rounded-lg">
            <div className="w-full h-full px-4 py-6 bg-gray-900 rounded-lg transform group-hover:scale-105 transition duration-300 ease-in-out shadow-lg">
              <div className="w-full flex justify-center items-center">
                <img
                  className="w-[8rem] h-[8rem] rounded-full outline outline-offset-2 outline-1 outline-amber-400 shadow-lg relative -top-[5rem]"
                  src="https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw2fHxwZW9wbGV8ZW58MHwwfHx8MTcxODQ3NDU1Mnww&ixlib=rb-4.0.3&q=80&w=1080"
                  alt="Profile Image"
                />
              </div>
              <div className="w-full h-full text-center flex flex-col gap-4 pt-10 relative -top-10">
                <h1 className="uppercase text-base font-semibold text-amber-500">
                Sarah M.
                </h1>
                <p className="text-[13px] text-gray-200 text-justify">
                As a newcomer to trading, I was nervous at first. But BotPay made everything so simple and easy to understand. The customer support team is always ready to help!

                </p>
                <div class="flex space-x-2 text-yellow-400 justify-center mt-2">
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative group" style={{ width: "20rem" }}>
          <div className="relative p-4 lg:mt-24 sm:mt-16 mt-0 max-w-sm mx-auto">
            <div className="flex mb-2 items-center justify-between">
              <div className="mb-2">
                <span className="text-xs font-semibold inline-block py-1  px-2 uppercase rounded-full text-amber-600 bg-amber-200">
                  In Progress
                </span>
              </div>
              <div className="text-right mb-2">
                <span className="text-xs font-semibold inline-block text-amber-600">
                  4/6
                </span>
              </div>
            </div>
            <div className="flex rounded-full h-2 bg-gray-200">
              <div
                style={{ width: "70%" }}
                className="rounded-full bg-amber-500"
              />
            </div>
          </div>
          <button type="button"
            class="flex absolute lg:top-[180px] left-3 z-40 items-center justify-center w-10 h-10 bg-amber-600 rounded-full hover:bg-amber-500 focus:outline-none transition"
            data-carousel-prev>
            <svg class="w-5 h-5 text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
            </svg>
        </button>
        <button type="button"
            class="flex absolute lg:top-[180px] right-48  z-40 items-center justify-center w-10 h-10 bg-amber-600 rounded-full hover:bg-amber-500 focus:outline-none transition"
            data-carousel-next>
            <svg class="w-5 h-5 text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
            </svg>
        </button>
        </div>
      </div>
    </div>
  );
};
